//interceptor network (not used cause blocker in unitest)

/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { URL_API_GATEWAY, GATEWAY_BASIC_AUTH } from '../config/keys';

const gatewayHttpService = Axios.create({
  baseURL: URL_API_GATEWAY,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
  },
  auth: {
    username: GATEWAY_BASIC_AUTH.USERNAME || 'defaultUsername',
    password: GATEWAY_BASIC_AUTH.PASSWORD || 'defaultPassword',
  },
});

gatewayHttpService.interceptors.request.use(
  // custom handle config
  (config) => {
    return {
      ...config,
      headers: {
        'content-type': 'application/json',
      },
    };
  },
  (error) => Promise.reject(error),
);

gatewayHttpService.interceptors.response.use(
  // custom handle response success
  async (res) => res.data,
  // custom handle response error
  async (error) => {
    if (error?.response) {
      const { status } = error.response;
      if (status === 401) {
        console.log('Unauthorized');
      }
    }

    return Promise.reject(error);
  },
);

export default gatewayHttpService;
